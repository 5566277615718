import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import {
  DESKTOP_WIDTH_TEXT_ON_LEFT,
  DESKTOP_WIDTH_TEXT_ON_RIGHT,
  LEFT_TEXT_IMAGE_DESKTOP_WIDTH,
  RIGHT_TEXT_IMAGE_DESKTOP_WIDTH,
} from './constants';
import type { OneMediaOverlayTextLayoutProps } from './types';

export const Root = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<Pick<OneMediaOverlayTextLayoutProps, 'position'>>(({ theme, position }) => {
  const desktopWidth = position === 'left' ? DESKTOP_WIDTH_TEXT_ON_LEFT : DESKTOP_WIDTH_TEXT_ON_RIGHT;

  return {
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    margin: theme.spacing(0, 'auto'),
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      maxWidth: desktopWidth,
    },
  };
});

export const TextWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<Pick<OneMediaOverlayTextLayoutProps, 'position'>>(({ theme, position }) => ({
  width: '90%',
  textAlign: 'center',
  margin: '0 auto',

  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    ...(position === 'left' ? { left: 0 } : { right: 0 }),
    top: '50%',
    transform: `translateY(-50%)`,
    width: 350,
    textAlign: 'initial',
    margin: 0,
  },
}));

export const ImageWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<Pick<OneMediaOverlayTextLayoutProps, 'position'>>(({ position, theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: position === 'left' ? LEFT_TEXT_IMAGE_DESKTOP_WIDTH : RIGHT_TEXT_IMAGE_DESKTOP_WIDTH,
    margin: theme.spacing(0, position === 'left' ? 0 : 'auto', 0, position === 'left' ? 'auto' : 0),
  },
}));
