import { styled } from '@ori-ui/mui';

import { LEFT_IMAGE_DESKTOP_WIDTH, RIGHT_IMAGE_DESKTOP_WIDTH } from './constants';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  justifyItems: 'center',
  gap: theme.spacing(1),
  padding: 0,
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
}));

export const LeftSide = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 0,
  [theme.breakpoints.up('md')]: {
    width: LEFT_IMAGE_DESKTOP_WIDTH,
    margin: theme.spacing(0, 0, 0, 6),
  },
}));

export const RightSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  justifyItems: 'center',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 545,
  },
}));

export const HeadingTitle = styled('div')(({ theme }) => ({
  margin: '0 auto',
  width: '90%',
  textAlign: 'center',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(3, 0, 0, 0),
    width: '100%',
  },
}));

export const RightImage = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  maxWidth: RIGHT_IMAGE_DESKTOP_WIDTH,
  alignSelf: 'center',
}));
