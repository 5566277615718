import { styled } from '@ori-ui/mui';

import { IMAGE_DESKTOP_WIDTH } from './constants';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: 0,
  },
}));

export const OneMediaRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    gap: 0,
  },
}));
export const ImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: 'auto',
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: IMAGE_DESKTOP_WIDTH,
  },
}));
export const TextWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  '.title-base': {
    margin: theme.spacing(4, 0),
    textAlign: 'center',
  },
  marginLeft: theme.spacing(0),
  overflow: 'hidden',
  letterSpacing: 0,
  p: {
    padding: theme.spacing(0, 3),
  },
  [theme.breakpoints.up('md')]: {
    width: 470,
    '.title-base': {
      margin: theme.spacing(4, 8, 4, 0),
    },
    marginLeft: theme.spacing(16),
  },
}));
