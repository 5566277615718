import { getDefaultLayoutProps } from '@ori-appshell/layouts/server';
import type { UserSegmentationQueryParams } from '@ori-appshell/rendering';
import { parseTenantLanguageParams, parseUserSegmentationParams } from '@ori-appshell/rendering';
import type { CategoryPageContainerProps } from '@ori/editorial-category';
import {
  CategoryPageContainer,
  defaultTranslations as categoryDefaultTranslations,
  translationsNamespace as categoryTranslationsNamespace,
} from '@ori/editorial-category';
import { getEditorialCategoryOrPageData } from '@ori/editorial-fetching';
import type { EditorialConfig, EditorialPageContainerProps } from '@ori/editorial-page';
import {
  EditorialPageContainer,
  defaultTranslations as editorialPageDefaultTranslations,
  translationsNamespace as editorialPageTranslationsNamespace,
} from '@ori/editorial-page';
import { olapicDefaultTranslations, olapicTranslationsNamespace } from '@ori/olapic-lib';
import type { GetStaticPaths, GetStaticProps, GetStaticPropsResult, NextPage } from 'next';
import { PageFeatures } from '~/common-data/PageFeatures';
import { REVALIDATE_INTERVAL_IN_SECONDS } from '~/constants';
import { PACKAGE_NAME } from '~/constants-generated';
import { defaultNamespacedTranslations } from '~/constants/translationKeys';
import { Layout, type LayoutProps } from '~/containers/Layout';
import { campaignsMainLogger } from '~/logger';
import { getEditorialPageId, getStaticPropsError } from '~/utils';

const editorialTranslations = {
  [editorialPageTranslationsNamespace]: editorialPageDefaultTranslations,
  [categoryTranslationsNamespace]: categoryDefaultTranslations,
  [olapicTranslationsNamespace]: olapicDefaultTranslations,
  ...defaultNamespacedTranslations,
};

type PageQueryParams = UserSegmentationQueryParams & {
  pageIdParts: string[];
};

type EditorialPageTranslations = typeof editorialTranslations;

type EditorialsPageProps = {
  layoutPage: LayoutProps<EditorialPageTranslations>;
} & (
  | {
      type: 'category';
      props: Omit<CategoryPageContainerProps, 'translations'>;
    }
  | {
      type: 'page';
      props: Omit<EditorialPageContainerProps, 'translations'>;
    }
);

const logger = campaignsMainLogger.createChild('getStaticProps');

export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
});

export const getStaticProps: GetStaticProps<Partial<EditorialsPageProps>, PageQueryParams> = async ({ params }) => {
  try {
    const { language, tenant } = parseTenantLanguageParams(params);
    const { atpClusterId, customerTypeId } = parseUserSegmentationParams(params);
    const { pageIdParts } = params ?? {};

    if (pageIdParts) {
      const pageId = getEditorialPageId(pageIdParts);

      const { servicesUrls, frontendToken, layoutPage } = await getDefaultLayoutProps<EditorialPageTranslations>({
        pageId,
        language,
        tenant,
        sourceAppName: PACKAGE_NAME,
        defaultTranslations: editorialTranslations,
        pageFeatures: Object.values(PageFeatures),
      });

      const config: EditorialConfig = {
        language,
        globalApiUrl: servicesUrls.globalApi.baseUrl,
        graphQlUrl: servicesUrls.graphQl.baseUrl,
        staticApiUrl: servicesUrls.staticApi.baseUrl,
        tenant,
        token: frontendToken,
      };

      const { breadcrumbs, editorialCategoryOrPageData, hideRating } = await getEditorialCategoryOrPageData({
        graphQlUrl: servicesUrls.graphQl.backendUrl,
        staticApiUrl: servicesUrls.staticApi.baseUrl,
        language,
        tenant,
        token: frontendToken,
        applicationName: PACKAGE_NAME,
        atpClusterId,
        customerTypeId,
        pageId,
      });

      const {
        editorialLandingPage,
        editorialLandingPages = [],
        editorialPage,
      } = editorialCategoryOrPageData.application ?? {};

      if (editorialLandingPage === null && editorialPage === null) {
        const resultProps: GetStaticPropsResult<EditorialsPageProps> = {
          props: {
            layoutPage,
            props: {
              breadcrumbs,
              config,
              pageId,
              data: null,
              hideRating,
            },
            type: 'page',
          },
          revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2,
        };

        return resultProps;
      }
      if (editorialLandingPage != null) {
        const resultProps: GetStaticPropsResult<EditorialsPageProps> = {
          props: {
            layoutPage,
            props: {
              breadcrumbs,
              pageId,
              data: { editorialLandingPage, editorialLandingPages },
            },
            type: 'category',
          },
          revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2,
        };

        return resultProps;
      }

      if (editorialPage != null) {
        const resultProps: GetStaticPropsResult<EditorialsPageProps> = {
          props: {
            layoutPage,
            props: {
              breadcrumbs,
              config,
              pageId,
              data: { editorialLandingPages, editorialPage },
              hideRating,
            },
            type: 'page',
          },
          revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2,
        };

        return resultProps;
      }
    }

    return { notFound: true, revalidate: REVALIDATE_INTERVAL_IN_SECONDS * 2 };
  } catch (error) {
    throw new Error(getStaticPropsError(logger, error));
  }
};

const Page: NextPage<EditorialsPageProps> = ({ layoutPage, props, type }) => {
  const { breadcrumbs } = props;
  if (type === 'category') {
    const { data, pageId } = props;

    return (
      <Layout layoutPage={layoutPage}>
        <CategoryPageContainer
          breadcrumbs={breadcrumbs}
          data={data}
          pageId={pageId}
        />
      </Layout>
    );
  }
  const { config, data, pageId, hideRating } = props;

  return (
    <Layout layoutPage={layoutPage}>
      <EditorialPageContainer
        breadcrumbs={breadcrumbs}
        config={config}
        data={data}
        pageId={pageId}
        hideRating={hideRating}
      />
    </Layout>
  );
};

export default Page;
